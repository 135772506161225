exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-feedback-jsx": () => import("./../../../src/pages/feedback.jsx" /* webpackChunkName: "component---src-pages-feedback-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-jobs-jsx": () => import("./../../../src/pages/jobs.jsx" /* webpackChunkName: "component---src-pages-jobs-jsx" */),
  "component---src-pages-our-work-jsx": () => import("./../../../src/pages/our-work.jsx" /* webpackChunkName: "component---src-pages-our-work-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */)
}

